import { Box, Typography, Button, Container } from "@mui/material";
import logo from '../assets/logo.png';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LanguageIcon from '@mui/icons-material/Language';
import useLanguage from "../providers/useLanguage";
import { Link } from "react-router-dom";

const Footer = () => {

  const { language: { footer: footerContent, navBar: navBarContent } } = useLanguage();

  const pages = [
    {
      title: navBarContent.home,
      to: '/'
    },
    {
      title: navBarContent.aboutUs,
      to: '/about-us'
    },
    {
      title: navBarContent.production,
      to: '/production'
    },
    {
      title: navBarContent.gallery,
      to: '/gallery'
    },
    {
      title: navBarContent.contact,
      to: '/contact'
    }
  ];

  return (
    <Box sx={{
      background: '#111111',
      py: 12,
    }}>
      <Container
        maxWidth="xl"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 8,
          flexWrap: 'wrap'
        }}>
        <Box sx={{
          maxWidth: '350px'
        }}>
          <Box
            component="img"
            src={logo}
            sx={{
              height: 80,
              width: 160,
            }}
          />
          <Typography
            sx={{
              color: 'white',
              opacity: '0.85',
              mt: 3
            }}
          >
            {footerContent.description}
          </Typography>
          <Typography
            sx={{
              color: 'white',
              opacity: '0.85',
              mt: 3
            }}
          >
            {footerContent.copyright}
          </Typography>
        </Box>
        <Box>
          <Box
            sx={{
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}>
            <Typography variant="h5" sx={{
              color: 'white',
              mb: 2,
              letterSpacing: '1px'
            }}>
              {footerContent.menu}
            </Typography>
            {pages.map(({ title, to }, idx) => (
              <Link to={to} style={{ textDecoration: 'none' }}>
                <Button
                  key={idx}
                  sx={{ color: 'white', display: 'block', textAlign: 'left' }}
                >
                  {title}
                </Button>
              </Link>
            ))}
          </Box>
        </Box>
        <Box>
          <Typography variant="h5" sx={{
            color: 'white',
            mb: 2,
            letterSpacing: '1px'
          }}>
            {footerContent.contactText}
          </Typography>
          <Box sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            gap: 2,
            opacity: '0.85',
            mt: 1
          }}>
            <LocationOnIcon sx={{ color: 'white' }} />
            <Typography sx={{ color: 'white' }}>
              {footerContent.contact.address}
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            gap: 2,
            opacity: '0.85',
            mt: 2
          }}>
            <LocalPhoneIcon sx={{ color: 'white' }} />
            <Typography sx={{ color: 'white' }}>
              {footerContent.contact.phone}
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            gap: 2,
            opacity: '0.85',
            mt: 2
          }}>
            <EmailIcon sx={{ color: 'white' }} />
            <Typography sx={{ color: 'white' }}>
              {footerContent.contact.email}
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            gap: 2,
            opacity: '0.85',
            mt: 2
          }}>
            <LanguageIcon sx={{ color: 'white' }} />
            <Typography sx={{ color: 'white' }}>
              {footerContent.contact.website}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer;