import { useEffect, useState } from "react";
import LanguageContext from "./languageContext";
import bg from './locales/bg.json';
import en from './locales/en.json';

const { Provider } = LanguageContext;

const languages = {
  'bg': bg,
  'en': en
}

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'bg');

  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language])


  return (
    <Provider value={{ language: languages[language], setLanguage }}>
      {children}
    </Provider>
  )
}

export default LanguageProvider;