import TopBar from './components/TopBar';
import NavBar from './components/Navbar';
import Footer from './components/Footer';

const App = ({ children }) => {

  return (
    <>
      <TopBar />
      <NavBar />
      {children}
      <Footer />
    </>
  )
}
export default App;
