import { Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Parallax } from 'react-parallax';
import parallaxImg from '../assets/pages/4.jpg';
import useLanguage from '../providers/useLanguage';

const AboutUs = () => {
  const { language: { aboutUs: content } } = useLanguage();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Parallax bgStyle={{
        backgroundPosition: 'bottom bottom',
        backgroundSize: 'cover',
      }} bgImage={parallaxImg} bgImageAlt="cow" strength={200}>
        <Box sx={{
          height: isMobile ? '30vh' : '40vh',
        }}>
        </Box>
      </Parallax>
      <Box sx={{
        py: 12,
      }}>
        <Container maxWidth="lg">
          <Typography variant="h3">
            {content.title}
          </Typography>
          <Typography sx={{
            mt: 5
          }}>
            {content.p1}
          </Typography>
          <Typography sx={{
            mt: 2
          }}>
            {content.p2}
          </Typography>
          <Typography sx={{
            mt: 2
          }}>
            {content.p3}
          </Typography>
          <Typography sx={{
            mt: 2
          }}>
            {content.p4}
          </Typography>
          <Typography sx={{
            mt: 2
          }}>
            {content.p5}
          </Typography>
        </Container>
      </Box>
    </>

  )
}

export default AboutUs;