import React, { useLayoutEffect } from 'react';
import './index.css';
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import App from './App';
import AboutUs from './pages/about-us';
import Home from './pages/home';
import Production from './pages/production';
import Gallery from './pages/gallery';
import Contact from './pages/contact';
import LanguageProvider from './providers/LanguageProvider';
import NotFound from './pages/not-found';

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LanguageProvider>
      <BrowserRouter>
        <Wrapper>
          <App>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="about-us" element={<AboutUs />} />
              <Route path="production" element={<Production />} />
              <Route path="gallery" element={<Gallery />} />
              <Route path="contact" element={<Contact />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </App>
        </Wrapper>
      </BrowserRouter>
    </LanguageProvider>

  </React.StrictMode>
);
