import { Box, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import MuiCarousel from 'react-material-ui-carousel'
import img1 from '../assets/carousel/carousel1.jpg';
import img2 from '../assets/carousel/carousel4.jpg';
import useLanguage from "../providers/useLanguage";

const Carousel = () => {
  const { language: { home: { carousel: content } } } = useLanguage();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  var carouselItems = [
    {
      title: content.one.title,
      description: content.one.description,
      src: img1
    },
    {
      title: content.two.title,
      description: content.two.description,
      src: img2
    }
  ]

  return (
    <MuiCarousel
      navButtonsAlwaysVisible={!isMobile}
      fullHeightHover={false}
      indicatorContainerProps={{
        style: {
          display: 'none'
        }
      }}>
      {carouselItems.map(({ title, description, src }, i) =>
        <CarouselItem key={i} title={title} description={description} src={src} />)}
    </MuiCarousel>
  )
}

const CarouselItem = ({ title, description, src }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Box
        component="img"
        src={src}
        sx={{
          height: 'calc(100vh - 123px)',
          width: '100%',
          objectFit: 'cover'
        }}
      >
      </Box>
      <Paper sx={{
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.25)',
        zIndex: 3
      }} />
      <Paper sx={{
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        background: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 5,
        flexDirection: 'column'
      }}>
        {!isMobile ? (
          <Box sx={{
            p: 4,
            background: 'rgba(0, 0, 0, 0.5)',
            textAlign: 'center',
            borderRadius: 0
          }}>
            <Typography sx={{
              color: 'white',
              fontSize: '3rem',
              fontWeight: 700,
              opacity: '0.9'
            }}>
              {title}
            </Typography>
            <Typography sx={{
              color: 'white',
              fontSize: '1rem',
              fontWeight: 500,
              opacity: '0.9'
            }}>
              {description}
            </Typography>
          </Box>
        ) : (
          <Box sx={{
            m: 3,
            p: 2,
            background: 'rgba(0, 0, 0, 0.5)',
            textAlign: 'center',
            borderRadius: 0
          }}>
            <Typography sx={{
              color: 'white',
              fontSize: '1.5rem',
              fontWeight: 700,
              opacity: '0.9'
            }}>
              {title}
            </Typography>
            <Typography sx={{
              color: 'white',
              fontSize: '1rem',
              fontWeight: 500,
              opacity: '0.9'
            }}>
              {description}
            </Typography>
          </Box>
        )}

      </Paper>
    </>

  )
}

export default Carousel;