import { Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import bg from '../assets/country/bg.png';
import uk from '../assets/country/uk.png';
import useLanguage from "../providers/useLanguage";

const TopBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { language: { topBar: content }, setLanguage } = useLanguage();

  return (
    <Box sx={{
      p: 1,
      px: 4,
      background: '#000',
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '20px',
      alignItems: 'center'
    }}>
      {!isMobile && (
        <Typography sx={{
          color: 'white',
          opacity: 0.85,
          fontSize: '14px'
        }}>
          {content.address}
        </Typography>
      )}
      <Typography sx={{
        color: 'white',
        opacity: 0.85,
        fontSize: '14px'
      }}>
        {content.phone}
      </Typography>
      <Link href="#" onClick={() => setLanguage('bg')} xs={{
        display: 'inline-flex'
      }}>
        <Box
          component="img"
          src={bg}
          sx={{
            height: '16px',
            width: '26px'
          }}
        />
      </Link>
      <Link href="#" onClick={() => setLanguage('en')} xs={{
        display: 'inline-flex'
      }}>
        <Box
          component="img"
          src={uk}
          sx={{
            height: '16px',
            width: '26px'
          }}
        />
      </Link>

    </Box>
  )
}

export default TopBar;