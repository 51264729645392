import * as React from 'react';
import { Box, Typography } from '@mui/material';
import useLanguage from '../providers/useLanguage';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LanguageIcon from '@mui/icons-material/Language';
import ContactComponent from '../components/ContactForm';

const Contact = () => {
  const { language: { contact: labelContent, footer: contactContent } } = useLanguage();

  return (
    <>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        py: 12,
        gap: 12,
        flexWrap: 'wrap'
      }}>
        <Box sx={{ textAlign: 'center' }}>
          <LocationOnIcon />
          <Typography variant="h6" sx={{ textTransform: 'uppercase', fontWeight: 700 }}>
            {labelContent.address}
          </Typography>
          <Typography>
            {contactContent.contact.address}
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <LocalPhoneIcon />
          <Typography variant="h6" sx={{ textTransform: 'uppercase', fontWeight: 700 }}>
            {labelContent.phone}
          </Typography>
          <Typography>
            {contactContent.contact.phone}
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <EmailIcon />
          <Typography variant="h6" sx={{ textTransform: 'uppercase', fontWeight: 700 }}>
            {labelContent.email}
          </Typography>
          <Typography>
            {contactContent.contact.email}
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <LanguageIcon />
          <Typography variant="h6" sx={{ textTransform: 'uppercase', fontWeight: 700 }}>
            {labelContent.website}
          </Typography>
          <Typography>
            {contactContent.contact.website}
          </Typography>
        </Box>
      </Box>
      <ContactComponent />
    </>
  )
}

export default Contact;