import * as React from 'react';
import { ImageList, ImageListItem, Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import useLanguage from '../providers/useLanguage';
import { Parallax } from 'react-parallax';
import parallaxImg from '../assets/pages/11.jpg';

import img1 from '../assets/gallery/IMG-6082.JPG';
import img2 from '../assets/gallery/IMG-6083.JPG';
import img3 from '../assets/gallery/IMG-6084.JPG';
import img4 from '../assets/gallery/IMG-6085.JPG';
import img5 from '../assets/gallery/IMG-6086.JPG';
import img6 from '../assets/gallery/IMG-6087.JPG';
import img7 from '../assets/gallery/IMG-6088.JPG';
import img8 from '../assets/gallery/IMG-6089.JPG';
import img9 from '../assets/gallery/IMG-6090.JPG';
import img10 from '../assets/gallery/IMG-6091.JPG';
import img11 from '../assets/gallery/IMG-6092.JPG';
import img12 from '../assets/gallery/IMG-6093.JPG';
import img13 from '../assets/gallery/IMG-6094.JPG';
import img14 from '../assets/gallery/IMG-6095.JPG';
import img15 from '../assets/gallery/IMG-6096.JPG';
import img16 from '../assets/gallery/IMG-6097.JPG';
import img17 from '../assets/gallery/IMG-6098.JPG';
import img18 from '../assets/gallery/IMG-6099.JPG';
import img19 from '../assets/gallery/IMG-6100.JPG';
import img20 from '../assets/gallery/IMG-6101.JPG';

const itemData = [
  {
    img: img1,
    title: 'gallery-image',
  },
  {
    img: img2,
    title: 'gallery-image',
  },
  {
    img: img3,
    title: 'gallery-image',
  },
  {
    img: img4,
    title: 'gallery-image',
  },
  {
    img: img5,
    title: 'gallery-image',
  },
  {
    img: img6,
    title: 'gallery-image',
  },
  {
    img: img7,
    title: 'gallery-image',
  },
  {
    img: img8,
    title: 'gallery-image',
  },
  {
    img: img9,
    title: 'gallery-image',
  },
  {
    img: img10,
    title: 'gallery-image',
  },
  {
    img: img11,
    title: 'gallery-image',
  },
  {
    img: img12,
    title: 'gallery-image',
  },
  {
    img: img13,
    title: 'gallery-image',
  },
  {
    img: img14,
    title: 'gallery-image',
  },
  {
    img: img15,
    title: 'gallery-image',
  },
  {
    img: img16,
    title: 'gallery-image',
  },
  {
    img: img17,
    title: 'gallery-image',
  },
  {
    img: img18,
    title: 'gallery-image',
  },
  {
    img: img19,
    title: 'gallery-image',
  },
  {
    img: img20,
    title: 'gallery-image',
  },
];

const Gallery = () => {
  const { language: { gallery: content } } = useLanguage();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Parallax bgStyle={{
        backgroundPosition: 'bottom bottom',
        backgroundSize: 'cover',
      }
      } bgImage={parallaxImg} bgImageAlt="cow" strength={200} >
        <Box sx={{
          height: isMobile ? '30vh' : '40vh',
        }}>
        </Box>
      </Parallax >

      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        py: 12
      }}>
        <Container maxWidth="lg">
          <Typography variant="h3" sx={{
            mb: 5
          }}>
            {content.title}
          </Typography>
          <ImageList variant="masonry" cols={isMobile ? 1 : 3} gap={8}>
            {itemData.map((item) => (
              <ImageListItem key={item.img}>
                <img
                  src={`${item.img}`}
                  srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Container>
      </Box>
    </>
  )
}

export default Gallery;