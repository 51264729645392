import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import img from '../assets/etc/drawing.jpg';
import useLanguage from "../providers/useLanguage";

const AboutUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { language: { home: { aboutUs: content } } } = useLanguage();

  return (
    <Box sx={{
      py: 12,
      px: 2,
      display: 'flex',
      justifyContent: 'center',
      gap: 8,
      flexWrap: isMobile ? 'wrap' : 'nowrap',
      background: '#111111'
    }}>
      <Box
        component="img"
        src={img}
        sx={{
          width: '100%',
          maxWidth: '500px'
        }}
      />
      <Box sx={{
        maxWidth: '700px',
      }}>
        <Typography sx={{
          color: 'white',
          fontSize: '32px',
          fontWeight: '700'
        }}>
          {content.title}
        </Typography>
        <Typography sx={{
          color: 'white',
          mt: 2,
          opacity: '0.9'
        }}>
          {content.descriptionOne}
        </Typography>
        <Typography sx={{
          color: 'white',
          mt: 2,
          opacity: '0.9'
        }}>
          {content.descriptionTwo}
        </Typography>
      </Box>
    </Box>
  )
}


export default AboutUs;