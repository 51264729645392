import { Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import SpaIcon from '@mui/icons-material/Spa';
import ComputerIcon from '@mui/icons-material/Computer';
import MoodIcon from '@mui/icons-material/Mood';
import useLanguage from "../providers/useLanguage";

const Features = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { language: { home: { features: content } } } = useLanguage();

  const features = [
    {
      icon: <SpaIcon />,
      title: content.one.title,
      description: content.one.description
    },
    {
      icon: <ComputerIcon />,
      title: content.two.title,
      description: content.two.description
    },
    {
      icon: <MoodIcon />,
      title: content.three.title,
      description: content.three.description
    }
  ]


  return (
    <Container maxWidth="xl">
      <Grid sx={{
        py: 12,
        display: 'flex',
        justifyContent: 'space-between',
        gap: 8,
        flexWrap: isMobile ? 'wrap' : 'nowrap'

      }}>
        {features.map(({ icon, title, description }, idx) =>
          <Feature icon={icon} title={title} description={description} />)}
      </Grid>
    </Container>
  )
}

const Feature = ({ icon, title, description }) => {
  return (
    <Grid item>
      <Grid sx={{
        display: 'flex',
        flexWrap: 'no-wrap',
        gap: '10px',
        alignItems: 'center',
        textAlign: 'left'
      }}>
        {icon}
        <Typography
          sx={{
            fontWeight: '700',
            alignSelf: 'center'
          }}>
          {title}
        </Typography>
      </Grid>
      <Typography sx={{
        mt: 1
      }}>
        {description}
      </Typography>
    </Grid>
  )
}

export default Features;