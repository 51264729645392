import AboutUs from "../components/AboutUs";
import Carousel from "../components/Carousel";
import ContactForm from "../components/ContactForm";
import Features from "../components/Features";
import Production from "../components/Production";


const Home = () => {
  return (
    <>
      <Carousel />
      <Features />
      <AboutUs />
      <Production />
      <ContactForm />
    </>
  )
}

export default Home;