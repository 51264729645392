import { FormControl, Box, Container, TextField, Card, Typography, Button } from "@mui/material";
import { useState } from "react";
import { Parallax } from 'react-parallax';
import parallaxImg from '../assets/carousel/carousel5.jpg';
import useLanguage from "../providers/useLanguage";

const ContactForm = () => {
  const { language: { contact: content } } = useLanguage();

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [message, setMessage] = useState(null);

  return (
    <Parallax bgImage={parallaxImg} bgImageAlt="cow" strength={200}>
      <Box sx={{
        py: 12,
      }
      }>
        <Container maxWidth="lg">
          <Card sx={{
            p: 5
          }}>
            <Typography variant="h4" sx={{
              mb: 4
            }}>
              {content.title}
            </Typography>
            <FormControl
              component="fieldset"
              variant="filled"
              disabled
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <TextField
                sx={{
                  maxWidth: '400px'
                }}
                hintText={content.name}
                floatingLabelText={content.name}
                label={content.name}
                error={name === ""}
                onChange={(event) => setName(event.target.value)}
                helperText={name === "" ? content.required : ' '}
              />
              <TextField
                sx={{
                  maxWidth: '400px'
                }}
                hintText={content.email}
                floatingLabelText={content.email}
                label={content.email}
                type="email"
                error={email === ""}
                onChange={(event) => setEmail(event.target.value)}
                helperText={email === "" ? content.required : ' '}
              />
              <TextField
                sx={{
                  maxWidth: '400px'
                }}
                hintText={content.phone}
                floatingLabelText={content.phone}
                label={content.phone}
                error={phone === ""}
                onChange={(event) => setPhone(event.target.value)}
                helperText={phone === "" ? content.required : ' '}
              />
              <TextField
                sx={{
                  // maxWidth: '400px'
                }}
                multiline
                rows={4}
                hintText={content.message}
                floatingLabelText={content.message}
                label={content.message}
                error={message === ""}
                onChange={(event) => setMessage(event.target.value)}
                helperText={message === "" ? content.required : ' '}
              />
              <Button variant="contained" sx={{
                background: 'black',
                ':hover': {
                  background: 'gray'
                }
              }}>
                {content.send}
              </Button>
            </FormControl>
          </Card>

        </Container>
      </Box >
    </Parallax >
  )
}

export default ContactForm;