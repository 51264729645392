import React, { useState } from 'react';
import logo from '../assets/logo.png';
import {
  useMediaQuery,
  useTheme,
  AppBar,
  Box,
  Toolbar,
  Container,
  Button,
  Drawer,
  IconButton
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import useLanguage from "../providers/useLanguage";
import { Link } from "react-router-dom";


const NavBar = () => {
  const { language: { navBar: content } } = useLanguage();
  const pages = [
    {
      title: content.home,
      to: '/'
    },
    {
      title: content.aboutUs,
      to: '/about-us'
    },
    {
      title: content.production,
      to: '/production'
    },
    {
      title: content.gallery,
      to: '/gallery'
    },
    {
      title: content.contact,
      to: '/contact'
    }
  ];

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AppBar
      position="sticky"
      sx={{
        background: '#fff',
        p: 1,
        boxShadow: 3,
      }}>
      <Container maxWidth="false">
        <Toolbar disableGutters>
          {isMobile ? (
            <DrawerComponent pages={pages} content={content} />
          ) : (
            <>
              <Link to="/" style={{ textDecoration: 'none' }}>
                <Box
                  component="img"
                  sx={{
                    height: 80,
                    width: 160,
                  }}
                  alt="The house from the offer."
                  src={logo}
                />
              </Link>
              <Box sx={{ justifyContent: 'center', flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map(({ title, to }, idx) => (
                  <Link to={to} style={{ textDecoration: 'none' }}>
                    <Button
                      key={idx}
                      sx={{ my: 2, color: 'black', display: 'block', textAlign: 'left' }}
                    >
                      {title}
                    </Button>
                  </Link>
                ))}
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <Link to="/contact" style={{ textDecoration: 'none' }}>
                  <Button sx={{ background: 'black', ':hover': { background: 'gray' } }} variant="contained">
                    {content.callToAction}
                  </Button>
                </Link>
              </Box>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar >
  );
};

const DrawerComponent = ({ pages, content }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'no-wrap',
      width: '100%',
    }}>
      <Link to="/" style={{ textDecoration: 'none' }}>
        <Box
          component="img"
          sx={{
            height: 70,
            width: 150,
          }}
          alt="The house from the offer."
          src={logo}
        />
      </Link>
      <Box sx={{
        alignSelf: 'center'
      }}>
        <Drawer
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{
            sx: {
              width: '80%',
              height: '100%',
              justifyContent: 'space-between'
            },
          }}
        >
          <Box sx={{ justifyContent: 'center', }}>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon sx={{
                color: 'black',
                fontSize: '30px',
                m: 1
              }} />
            </IconButton>
            {pages.map(({ title, to }, idx) => (
              <Link to={to} style={{ textDecoration: 'none' }}>
                <Button
                  key={idx}
                  onClick={handleClose}
                  sx={{ m: 2, color: 'black', display: 'block', textAlign: 'left' }}
                >
                  {title}
                </Button>
              </Link>
            ))}
          </Box>
          <Box sx={{ m: 2, justifyContent: 'flex-end' }}>
            <Link to="/contact" style={{ textDecoration: 'none' }}>
              <Button onClick={handleClose} sx={{ background: 'black', ':hover': { background: 'gray' } }} variant="contained">
                {content.callToAction}
              </Button>
            </Link>
          </Box>
        </Drawer>
        <IconButton onClick={() => setOpen(!open)}>
          <MenuIcon sx={{
            fontSize: '24px',
            color: 'black',
          }} />
        </IconButton>
      </Box>
    </Box >
  )
}

export default NavBar;
