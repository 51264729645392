import { Box, Grid, Typography, Container, useMediaQuery, useTheme } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import useLanguage from "../providers/useLanguage";

const Production = ({ title = true }) => {
  const { language: { production: content } } = useLanguage();

  const productionSteps = [
    {
      step: 1,
      title: content.one.title,
      description: content.one.description,
      color: '#6fbf73'
    },
    {
      step: 2,
      title: content.two.title,
      description: content.two.description,
      color: '#33ab9f'
    },
    {
      step: 3,
      title: content.three.title,
      description: content.three.description,
      color: '#ffcd38'
    },
    {
      step: 4,
      title: content.four.title,
      description: content.four.description,
      color: '#ff784e'
    },
    {
      step: 5,
      title: content.five.title,
      description: content.five.description,
      color: '#f6685e'
    },
    {
      step: 6,
      title: content.six.title,
      description: content.six.description,
      color: '#6573c3'
    }
  ]
  return (
    <Box sx={{
      py: 12,
    }}>
      <Container maxWidth="lg">
        {title && (<Typography sx={{
          fontWeight: '700',
          fontSize: '30px',
          width: '100%',
          textAlign: 'center'
        }}>
          {content.title}
        </Typography>)}
        <Grid sx={{
          mt: 4,
          display: 'flex',
          justifyContent: 'center',
          gap: 4,
          flexWrap: 'wrap',
        }}>
          {productionSteps.map(({ step, title, description, color }) => (
            <ProductionStep step={step} title={title} description={description} color={color} />
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

const ProductionStep = ({ step, title, description, color }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Card sx={{
      p: 2,
      boxShadow: 2,
      flex: isMobile ? '50%' : '33%',
    }}>
      <CardContent sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1
      }}>
        <Box sx={{
          background: color,
          display: 'inline',
          width: '80px',
          textAlign: 'center',
          boxShadow: 3,
        }}>
          <Typography variant="h3" sx={{
            fontWeight: '700',
            color: 'white',
            opacity: '0.9',
            textShadow: '2px 2px #ff0000'
          }}>
            {step}
          </Typography>
        </Box>
        <Typography variant="h5" sx={{
          mt: 1,
          fontWeight: '500',
        }}>
          {title}
        </Typography>
        <Typography sx={{
          opacity: '0.75'
        }}>
          {description}
        </Typography>
      </CardContent>
    </Card>
  )
}



export default Production;